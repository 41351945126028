<template>
  <div>
    <w-top-bar>Audio - Complemento de compra</w-top-bar>
    <w-record-audio
      :key="showRecordAudio._id"
      :value="showRecordAudio"
      v-if="showRecordAudio"
      @hide="showRecordAudio = false"
      @nextOrClose="nextOrClose"
    />
    <div class="h-full p-5 bg-gray-200">
      {{ regaloAudio }}
    </div>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import { makeFindMixin } from "feathers-vuex";

export default {
  name: "regaloAudioList",
  data() {
    return {
      listKey: 1,
      mostrarProcesados: false,
      showRecordAudio: false,
      listado: [],
    };
  },
  created() {
    this.load();
  },
  computed: {
    regaloAudioParams() {
      return {
        query: {},
      };
    },
  },

  mixins: [makeFindMixin({ service: "regalo-audio" })], // Step 2
  methods: {
    ...mapActions("regalo-audio", ["find"]),
    async load() {
      const resultado = await find({
        query: {
          $limit: 100,
          $sort: {
            createdAt: -1,
          },
        },
      });

      console.log("listado", resultado);
      console.log("listado", resultado.data);
    },

    async nextOrClose() {
      console.log("Next or close", this.showRecordAudio);
      this.regaloAudio[this.showRecordAudio.index].procesado = true;
      // Reload list
      this.listKey++;

      // this.updateView();
      if (this.showRecordAudio && this.showRecordAudio.index !== null) {
        console.log("Index", this.showRecordAudio.index);
        let nextIndex = this.showRecordAudio.index++;
        let existe = this.regaloAudio[nextIndex];
        while (existe && existe.estado === "procesado") {
          nextIndex++;
          existe = this.regaloAudio[nextIndex];
        }
        // Buscamos incrementando el index, mientras estado === 'procesado'

        console.log("Existe el siguiente", existe);
        if (existe)
          this.showRecordAudio = {
            audio: existe,
            index: this.showRecordAudio.index + 1,
          };
        else this.showRecordAudio = false;
      } else {
        this.showRecordAudio = false;
      }
    },
    async toRemove(id) {
      console.log(id);
      await this.remove(id);
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  